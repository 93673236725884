/** @format */
import { CheckIcon } from "@heroicons/react/24/outline";
import getConfig from "next/config";
import Image from "next/image";
import { useRouter } from "next/router";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

export default function Panel7() {
  const router = useRouter();
  const redirectTo = (page) => {
    router.push({
      pathname: `/${page}`,
    });
  };

  return (
    <div
      id="panel-7"
      className="flex items-center justify-center w-full text-brown-900 my-14 md:my-32"
    >
      <div className="container w-full">
        <div className="mb-16">
          <div className="mb-8 text-3xl font-bold text-center md:text-4xl 3xl:text-5xl">
            Level-Up The Way You Manage Your Fleet
          </div>
          <div className="text-base font-medium text-center">
            Modernize your fleet operations with more accurate fleet data,
            AI-powered reporting and powerful integrations.
          </div>
        </div>
        <div className="relative flex flex-col w-full mt-14 md:mt-32">
          <div className="container relative flex flex-col items-center justify-center md:flex-row">
            <div className="flex flex-col w-full gap-y-10 md:gap-y-16 xl:gap-y-32 place-items-center">
              <div className="flex flex-col items-center justify-between w-full md:flex-row gap-y-2 md:gap-x-20">
                <div className="md:w-[50%]">
                  <div className=" max-w-[400px] xl:max-w-[500px]">
                    <div>
                      <div className="mb-4 text-xl font-bold text-center md:text-left md:mb-8 md:text-3xl">
                        Detailed Spending Data, in Real-Time
                      </div>
                      <div className="flex flex-col gap-3 text-base md:text-base">
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Get a 360° view of your spend from anywhere, at
                          anytime.
                        </div>
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Receive expense analytics insights on a weekly /
                          monthly basis.
                        </div>
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Receive reports about spending patterns and historical
                          trends.
                        </div>
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Access itemized digital receipts for each of your
                          purchases.
                        </div>
                      </div>

                      {/* <Button
                        variant={ButtonVariant.Transparent}
                        className="!pl-0 mt-4 mx-auto md:ml-0"
                        onClick={() => {
                          redirectTo("integrations");
                        }}
                      >
                        Learn More{" "}
                        <ArrowCircleRightIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
                      </Button> */}
                    </div>
                  </div>
                </div>
                <div className="relative md:w-[50%] order-1 lg:order-2 w-[300px]">
                  <Image
                    layout="responsive"
                    width={680.1}
                    height={597.72}
                    objectFit="contain"
                    priority={true}
                    className="relative "
                    src={`${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_management/detailed-spending-data.png`}
                    alt="Detailed Spending Data"
                  ></Image>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between w-full md:flex-row gap-y-2 md:gap-x-20">
                <div className="md:w-[50%] order-1 md:order-2">
                  <div className="  max-w-[400px] xl:max-w-[480px]">
                    <div>
                      <div className="mb-4 text-xl font-bold text-center md:mb-8 md:text-3xl md:text-left">
                        Integrate with your favorite telematics, fleet
                        management and accounting tools!
                      </div>
                      <div className="flex flex-col gap-3 text-base md:text-base">
                        Easily integrate RoadFlex with your favorite fleet
                        management, telematics and accounting tools. This will
                        allow you to block suspicious transactions, centralize
                        all of your fleet data and close your books faster than
                        before!
                      </div>
                      {/* <Button
                        variant={ButtonVariant.Transparent}
                        className="!pl-0 mt-4 mx-auto md:ml-0"
                        onClick={() => {
                          redirectTo("integrations");
                        }}
                      >
                        Learn More{" "}
                        <ArrowCircleRightIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
                      </Button> */}
                    </div>
                  </div>
                </div>
                <div className="relative md:w-[50%] order-2 md:order-1 w-[300px]">
                  <Image
                    layout="responsive"
                    width={656}
                    height={660}
                    objectFit="contain"
                    priority={true}
                    className="relative"
                    src={`${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_management/telematics-connection.png`}
                    alt="Telematics integration"
                  ></Image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
