/** @format */
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { useReadAdminBlogs } from "@roadflex/react-hooks";
import classNames from "classnames";
import getConfig from "next/config";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL, NEXT_PUBLIC_API_URL, APP_ENV } =
  publicRuntimeConfig;
SwiperCore.use([Navigation]);

export default function Panel11() {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const { blogList, blogListLoading } = useReadAdminBlogs();

  const finalBlogList = blogList?.readAdminBlogs.blogs || [];

  return (
    <div
      id="panel-11"
      className={`w-full py-14 md:py-32 ${
        blogListLoading || finalBlogList.length === 0 ? "hidden" : ""
      }`}
    >
      <div className="container ">
        <div className="">
          <div className="mb-16">
            <div className="mb-4 font-bold text-orange-500">
              ROADFLEX&apos;S LATEST
            </div>
            <div className="mb-4 text-2xl font-bold text-brown-800 md:text-3xl 3xl:text-4xl">
              Fleet Analytics Insights
            </div>
            {/* <div className="text-base text-center">{subHeader}</div> */}
          </div>
          <Swiper
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            breakpoints={{
              // 1500: { slidesPerView: 3.5, slidesPerGroup: 1, spaceBetween: 12 },
              // 1350: { slidesPerView: 5, slidesPerGroup: 1, spaceBetween: 12 },
              998: { slidesPerView: 3, slidesPerGroup: 1, spaceBetween: 20 },
              625: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 12 },
              //   425: { slidesPerView: 1.5, slidesPerGroup: 1, spaceBetween: 12 },
              0: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 12 },
            }}
            onActiveIndexChange={(swiper: SwiperCore) => {
              setActiveIndex(swiper.activeIndex);
            }}
            lazyPreloadPrevNext={3}
            className="child:!ease-linear"
          >
            {finalBlogList.length > 0 &&
              finalBlogList.map((blog, i: number) => (
                <SwiperSlide key={blog.id}>
                  <div className="flex flex-col rounded-md">
                    <div
                      className={classNames(`h-[full]`, "relative mb-4 w-full")}
                    >
                      <Image
                        src={`${
                          APP_ENV === "production"
                            ? `${NEXT_PUBLIC_API_URL}/api/files/${blog.titleImage}`
                            : `https://api.roadflex.com/api/files/${blog.titleImage}`
                        }`}
                        layout="responsive"
                        width={460}
                        height={263}
                        sizes="(min-width: 1360px) 403px, (min-width: 1000px) calc(27.65vw + 33px), (min-width: 640px) calc(50vw - 22px), (min-width: 340px) calc(100vw - 32px), 448px"
                        loading="eager"
                        // height={i % 2 === 0 ? "300px" : "180px"}
                        alt="RoadFlex Partners"
                      />
                    </div>
                    <div className="mt-1">
                      <div className="text-xl font-bold text-black whitespace-pre-wrap">
                        <Link href={`blog/${blog.blogUrlId}`} prefetch={false}>
                          <a className="text-gray-700 ">{blog.title}</a>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="h-full mt-3 flex-grow-1">
                      {blog.summary}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            {finalBlogList.length > 1 && (
              <div className="flex items-center gap-8 mt-4 md:mt-16">
                <button
                  className="flex disabled:opacity-50"
                  // ref={navigationPrevRef}
                  onClick={() => {
                    swiperInstance.slidePrev();
                  }}
                  disabled={activeIndex === 0}
                >
                  <ArrowLeftIcon className="flex w-6 h-6 text-gray-500 md:w-12 md:h-12"></ArrowLeftIcon>
                </button>

                <button
                  onClick={() => {
                    swiperInstance.slideNext();
                  }}
                  className="flex disabled:opacity-50"
                  disabled={
                    activeIndex ===
                    finalBlogList.length -
                      ((swiperInstance.params.breakpoints[
                        swiperInstance.currentBreakpoint
                      ].slidesPerView as unknown as number) || 3)
                  }
                >
                  <ArrowRightIcon className="flex w-6 h-6 text-gray-500 md:w-12 md:h-12"></ArrowRightIcon>
                </button>
              </div>
            )}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
